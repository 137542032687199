.default {
  position: relative;
  overflow: visible;
}

.contentWrapper {
  background-color: rgba(249, 250, 255, 0.4);
  color: #16171b;
  border-radius: 4px;
}

.content {
  background: linear-gradient(235.44deg, #35ea94 10.36%, #5db8fb 52.71%, #ef68a3 96.87%);
  min-height: 72vh;
}

header.mainHeader {
  height: 90px;
  position: relative;
  z-index: 0;
}

.btnMenu {
  display: block;
  text-align: center;
  padding: 12px;
  vertical-align: middle;
  font-size: 24px;
}

.btnMenu::before {
  display: block;
}

.logo {
  display: block;
}

.navLink {
  color: #f9faff !important;
  font-weight: 300 !important;
  font-size: 14px !important;
}

.navLink:hover {
  color: #ef68a3 !important;
}

.btnWrapper {
  background: #16171b;
  position: relative;
  background: linear-gradient(30deg, #5db8fb, #ef68a3, #35ea94);
  border-radius: 4px;
  padding: 1px;
  display: flex;
}

.signUp,
.btn-try-empty {
  width: 100%;
  height: 48px;
  border: none;
  background-color: #16171b;
  color: #f9faff;
  font-weight: 300;
}

.signUp:hover,
.btn-try-empty:hover {
  background: linear-gradient(30deg, #5db8fb, #ef68a3, #35ea94);
  color: #f9faff;
}

.signUp:focus,
.btn-try-empty:focus {
  background-color: #16171b;
  box-shadow: none !important;
}

.address {
  color: #e6e6e7;
  font-size: 14px;
}

a.mailLink:hover {
  color: #ef68a3;
}

small.copyright {
  text-align: center;
  color: #8a8a8a;
  font-size: 12px;
}

@media screen and (max-width: 320px) {
  .navigation ul {
    width: 100%;
  }
}

@media (max-width: 768px) {
  header.mainHeader {
    height: 64px;
  }

  .signUp {
    height: 32px;
    font-size: 14px;
  }

  .address {
    display: block;
    text-align: center;
    font-size: 14px;
  }
}
